var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-sheet",
    { staticClass: "lessonSearch", attrs: { color: "#ffffff" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c("lesson-lists-table", {
                    ref: "lessonListsTable",
                    attrs: {
                      items: _vm.lessonArray,
                      excludeHeaders: [
                        "extraLesson",
                        "dateLabel",
                        "day",
                        "sorter",
                        "lessonNum",
                      ],
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }