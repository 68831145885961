
















import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LessonListItem from '@/components/lessonlists/LessonListItem.vue';
import ld from 'lodash';
import { Debounce } from 'vue-debounce-decorator';
import LessonListsTable from '@/components/lessonlists/LessonListsTable.vue';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';

const plans = namespace('plans');
@Component({
  mixins: [PageLifeCycleMixin],
  components: {
    LessonListItem,
    LessonListsTable
  }
})
export default class LessonSearch extends Vue {
  editMode = false;
  selectedRows = [];
  typing = false;

  headers = [
    { sortable: false, width: '1%', value: 'action' },
    { text: this.$t('classLabel'), value: 'searchClassName', width: '13%', sortable: false },
    { text: this.$t('yearLabel'), value: 'searchYear', width: '10%', sortable: false },
    { text: this.$t('dateLabel'), value: 'searchDate', width: '10%', sortable: false },
    { text: this.$t('dayLabel'), value: 'searchDayOfWeek', width: '10%', sortable: false },
    { text: this.$t('lessonLabel'), value: 'lessonTitle', width: '70%', sortable: false, align: 'center' }
  ];

  $refs!: {
    lessonListsTable:LessonListsTable
  }

  @plans.Action
  getSearchedLessons!: (params?:any) => Promise<any>;

  @plans.Getter('getSearchLessonArray')
  searchLessonArray!: Array<any>;

  @plans.Getter('getSearchLessonString')
  seachLessonString!: string;

  get lessonArray(): Array<any> {
    return this.searchLessonArray;
  }

  set lessonArray(val: Array<any>) {
    this.$store.commit('plans/setSearchLessonArray', val);
  }

  get search(): string {
    return this.seachLessonString;
  }

  set search(val: string) {
    this.$store.commit('plans/setSearchLessonString', val);
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  doSearchLesson(resetList:boolean, autoSave?:boolean) {
    if (CommonUtils.hasText(this.search)) {
      if (!autoSave) CommonUtils.showLoading();
      this.getSearchedLessons({
        searchString: this.search
      }).then((resp:any) => {
        this.$refs.lessonListsTable.localInfiniteScrollResetAllowed = resetList;
        this.lessonArray = ld.cloneDeep(resp.dataObjects);
        CommonUtils.hideLoading();
      })
    }
  }

  clearSearch() {
    this.search = '';
    this.lessonArray = [];
  }

  @Watch('search')
  @Debounce(500)
  onSearchChange() {
    this.typing = false;
  }

  mounted() {
    const that = this;
    that.$refs.lessonListsTable.localInfiniteScrollResetAllowed = true;
    this.populateListData();
    this.$nextTick(() => {
      this.$eventBus.$on('lessonHasBeenSave', (params?:any) => {
        let autoSave = false;
        if (params) {
          autoSave = params.autoSave;
        }
        that.doSearchLesson(false, autoSave);
      });
    });
  }

  destroyed() {
    this.$eventBus.$off('lessonHasBeenSave');
  }

  populateListData() {
    const tempLessonArray = ld.cloneDeep(this.lessonArray);
    this.lessonArray = [];
    this.lessonArray = tempLessonArray;
  }
}
